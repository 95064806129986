// @flow
import * as React from "react"
import { IntlProvider as Intl, addLocaleData } from "react-intl"

// Locale data
import enData from "react-intl/locale-data/en"
import ptData from "react-intl/locale-data/pt"

// i18n Strings
import en from "../i18n/en.json"
import pt from "../i18n/pt.json"

import flatten from "../utils/flatten"

// Setup intl
addLocaleData([...enData, ...ptData])
const messages = {
  en: flatten(en),
  pt: flatten(pt)
}

const IntlProvider = ({ children, locale }) => {
  return (
    <Intl locale={locale} messages={messages[locale]}>
      {children}
    </Intl>
  )
}

export default IntlProvider
