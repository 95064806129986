import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"

import LocalizedLink from "../LocalizedLink/LocalizedLink"

export const Link = styled(LocalizedLink)``

export const Wrapper = styled.header`
  width: 100vw;

  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1280px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 40px;

  @media (max-width: 550px) {
    padding: 20px;
  }
`

export const Nav = styled.nav`
  width: auto;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;

  z-index: 1;

  @media (max-width: 550px) {
    position: absolute;
    width: 180px;
    top: ${({ visible }) => (visible ? "64px" : "80px")};
    right: 16px;

    padding: 24px;
    opacity: ${({ visible }) => (visible ? 1 : 0)};

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    background: #fff;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);

    border-radius: 4px;

    transition: opacity 0.2s ease-in-out, top 0.5s cubic-bezier(0.2, 1, 0.2, 1);
  }
`

export const Logo = styled.img`
  flex-shrink: 0;
  height: 18px;
  width: auto;
`

export const NavLink = styled(LocalizedLink).attrs({
  activeClassName: "is-active",
})`
  display: block;
  position: relative;
  text-decoration: none;

  margin-left: 24px;

  color: #000;

  cursor: pointer;

  &:after {
    content: "";
    display: block;
    position: absolute;
    opacity: 0;

    bottom: -4px;

    height: 1px;
    width: 100%;

    background-color: #000;

    transition: opacity 0.15s ease-in-out;
  }

  &:hover:after {
    opacity: 1;

    @media (max-width: 550px) {
      opacity: 0;
    }
  }

  &.is-active {
    pointer-events: none;

    &:after {
      opacity: 1;
    }
  }

  @media (max-width: 550px) {
    padding: 8px;
    margin-left: 0px;
  }
`

export const ExternalLink = styled.a`
  display: block;
  position: relative;
  text-decoration: none;

  margin-left: 24px;

  color: #000;

  cursor: pointer;

  &:after {
    content: "";
    display: block;
    position: absolute;
    opacity: 0;

    bottom: -4px;

    height: 1px;
    width: 100%;

    background-color: #000;

    transition: opacity 0.15s ease-in-out;
  }

  &:hover:after {
    opacity: 1;

    @media (max-width: 550px) {
      opacity: 0;
    }
  }

  &.is-active {
    pointer-events: none;

    &:after {
      opacity: 1;
    }
  }

  @media (max-width: 550px) {
    padding: 8px;
    margin-left: 0px;
  }
`

export const Burger = styled.div.attrs({
  ariaRole: "button",
  tabIndex: 0,
})`
  display: none;
  position: relative;
  height: 24px;
  width: 20px;

  cursor: pointer;

  @media (max-width: 550px) {
    display: block;
  }

  &:focus {
    outline: none;
  }

  &:after,
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: ${({ visible }) => (visible ? "11px" : "6px")};

    height: 2px;
    width: 100%;

    background-color: #000;

    transform: ${({ visible }) => (visible ? "rotate(45deg)" : "rotate(0deg)")};
    transition: all 0.15s ease-in-out;
  }

  &:before {
    top: ${({ visible }) => (visible ? "11px" : "auto")};
    bottom: 6px;

    transform: ${({ visible }) =>
      visible ? "rotate(-45deg)" : "rotate(0deg)"};
  }
`
