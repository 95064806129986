import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import GlobalStyles from "../GlobalStyles/GlobalStyles"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import IntlProvider from "../../i18n/index"
import useLocaleRedirect from "./UseLocalRedirect"
import { FormattedMessage, injectIntl } from "react-intl"
import { Helmet } from "react-helmet"
import logoImage from "../../images/adamant-logo.svg"

const injectGetMessage = fn =>
  React.createElement(injectIntl(({ intl }) => fn(intl.formatMessage)))

const Layout = ({ children, locale, metadata }) => {
  // let locale = "en"
  useLocaleRedirect()

  return (
    <IntlProvider locale={locale}>
      <>
        {injectGetMessage(getMessage => (
          <Helmet>
            <title>{getMessage({ id: metadata.title })}</title>
            <meta
              name="description"
              content={getMessage({ id: metadata.description })}
            />
            <meta name="image" content={logoImage} />
            <meta
              property="og:title"
              content={getMessage({ id: metadata.title })}
            />
            <meta
              property="og:description"
              content={getMessage({ id: metadata.description })}
            />
            <meta property="og:image" content={logoImage} />
          </Helmet>
        ))}
        <GlobalStyles />
        <Header locale={locale} />
        {children}
        <Footer locale={locale} />
      </>
    </IntlProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
