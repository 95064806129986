import React from "react"

import logoImage from "../../images/logo-mobile.svg"
import { Text, Small } from "../UI/Type/index"
import SwitchLangLink from "../SwitchLangLink/SwitchLangLink"

import { FormattedMessage } from "react-intl"

import * as S from "./style"

const Footer = ({ locale }) => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.Nav>
          <S.Column>
            <S.ColHeader>
              <S.LogoLink to={"/"}>
                <S.Logo src={logoImage} />
              </S.LogoLink>
            </S.ColHeader>
            <S.ColContent>
              <S.FooterLink to={"/legal"}>
                <Text medium>
                  <FormattedMessage id="topnav.legal.legal_information" />
                </Text>
              </S.FooterLink>
              <Small>
                <FormattedMessage id="topnav.legal.third_party" />
              </Small>
              <Small>
                <SwitchLangLink />
              </Small>
            </S.ColContent>
          </S.Column>
          <S.Column>
            <S.ColHeader>
              <Text medium>
                <FormattedMessage id="topnav.links" />
              </Text>
            </S.ColHeader>
            <S.ColContent>
              <S.FooterLink locale={locale} to={"/company"}>
                <FormattedMessage id="topnav.company" />
              </S.FooterLink>
              <S.FooterLink to={"/services"}>
                <FormattedMessage id="topnav.services" />
              </S.FooterLink>
              <S.Link href={`mailto:general@adamantsec.com`}>
                <FormattedMessage id="topnav.contact" />
              </S.Link>
            </S.ColContent>
          </S.Column>
          <S.Column>
            <S.ColHeader>
              <Text medium>
                <FormattedMessage id="topnav.information" />
              </Text>
            </S.ColHeader>
            <S.ColContent>
              <Text medium>Adamant Sec S.A.</Text>
              <S.Link to={"tel:00351913947194"}>+351 913 947 194</S.Link>
              <S.Link href={`mailto:general@adamantsec.com`}>
                general@adamantsec.com
              </S.Link>
            </S.ColContent>
          </S.Column>
        </S.Nav>
        <S.Button href={`mailto:general@adamantsec.com`}>
          <FormattedMessage id="topnav.get_in_touch" />
        </S.Button>
      </S.Container>
    </S.Wrapper>
  )
}

export default Footer
