// useLocaleRedirect.js
import React from "react"
import { navigate } from "gatsby"

const useLocaleRedirect = defaultLocale => {
  React.useEffect(() => {
    const hasRedirected = !!sessionStorage.getItem("redirected")
    const hasLocaleInPath = /\/[a-z]{2}$|\/[a-z]{2}\//gim.test(
      window.location.pathname
    )

    if (hasRedirected) {
      return
    }

    if (hasLocaleInPath) {
      return sessionStorage.setItem("redirected", true)
    }

    let shouldRedirect = false
    const portugueseLocales = ["pt", "pt-PT", "pt-BR"]
    navigator.languages.forEach(lang => {
      if (portugueseLocales.indexOf(lang) >= 0) {
        shouldRedirect = true
      }
    })

    if (shouldRedirect) {
      navigate("/pt" + window.location.pathname)
    }

    return sessionStorage.setItem("redirected", true)
  }, [])
}

export default useLocaleRedirect
