import React from "react"
import logoImage from "../../images/adamant-logo.svg"
import * as S from "./style"
import { FormattedMessage } from "react-intl"

const Header = ({ locale }) => {
  const [visible, setVisible] = React.useState(false)

  return (
    <S.Wrapper>
      <S.Container>
        <S.Link to="/">
          <S.Logo src={logoImage} />
        </S.Link>
        <S.Burger onClick={() => setVisible(!visible)} visible={visible} />
        <S.Nav visible={visible}>
          <S.NavLink to={"/company"}>
            <FormattedMessage id="mobileNav.company" />
          </S.NavLink>
          <S.NavLink to={"/services"}>
            <FormattedMessage id="mobileNav.services" />
          </S.NavLink>
          {
            //<S.NavLink to="/adamantium">Adamantium</S.NavLink>
          }
          <S.ExternalLink href={`mailto:general@adamantsec.com`}>
            <FormattedMessage id="mobileNav.contact" />
          </S.ExternalLink>
        </S.Nav>
      </S.Container>
    </S.Wrapper>
  )
}

export default Header
