import styled from "styled-components"

import { Link } from "gatsby"

export const Button = styled(Link)`
  display: flex;
  align-self: flex-start;
  flex-shrink: 0;

  padding: 12px 20px;
  border-radius: 4px;

  background-color: rgba(0, 0, 0, 1);
  color: #fff;

  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`
