import GatsbyLink from "gatsby-link"
import styled from "styled-components"

export const NavLink = styled(GatsbyLink).attrs({
  // activeClassName: "is-active",
})`
  display: block;
  position: relative;
  text-decoration: none;

  margin-left: 24px;
  margin-top: 8px;

  color: #000;

  cursor: pointer;

  padding-left: 0px;
  margin-left: 0px;
  font-weight: 600;

  &:after {
    content: "";
    display: block;
    position: absolute;
    opacity: 0;

    bottom: -4px;

    height: 1px;
    width: 100%;

    background-color: #000;

    transition: opacity 0.15s ease-in-out;
  }

  &:hover:after {
    opacity: 1;

    @media (max-width: 550px) {
      opacity: 0;
    }
  }

  &.is-active {
    pointer-events: none;

    &:after {
      opacity: 1;
    }
  }

  @media (max-width: 550px) {
    padding: 8px;
    padding-left: 0px;
    margin-left: 0px;
  }
`

export const ExternalLink = styled.a`
  display: block;
  position: relative;
  text-decoration: none;

  margin-left: 24px;

  color: #000;

  cursor: pointer;

  &:after {
    content: "";
    display: block;
    position: absolute;
    opacity: 0;

    bottom: -4px;

    height: 1px;
    width: 100%;

    background-color: #000;

    transition: opacity 0.15s ease-in-out;
  }

  &:hover:after {
    opacity: 1;

    @media (max-width: 550px) {
      opacity: 0;
    }
  }

  &.is-active {
    pointer-events: none;

    &:after {
      opacity: 1;
    }
  }

  @media (max-width: 550px) {
    padding: 8px;
    margin-left: 0px;
  }
`
