import styled from "styled-components"

import { Link as GatsbyLink } from "gatsby"
import LocalizedLink from "../LocalizedLink/LocalizedLink"

export const Wrapper = styled.section`
  width: 100vw;

  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;

  padding: 120px 40px 120px 40px;

  @media (max-width: 768px) {
    padding: 80px 40px 40px 40px;
    flex-wrap: wrap;
  }

  @media (max-width: 550px) {
    padding: 48px 20px 20px 20px;

    flex-direction: column;
  }
`

export const Nav = styled.nav`
  width: auto;
  height: auto;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  z-index: 1;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`

export const Column = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  margin-right: 80px;

  @media (max-width: 768px) {
    margin-right: 48px;
    margin-bottom: 40px;
  }

  @media (max-width: 550px) {
    margin-bottom: 32px;
  }
`

export const ColHeader = styled.div`
  margin-bottom: 24px;

  @media (max-width: 550px) {
    margin-bottom: 12px;
  }
`

export const ColContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  max-width: 240px;

  * {
    margin-bottom: 8px;

    @media (max-width: 550px) {
      margin-bottom: 4px;
    }
  }
`

export const Logo = styled.img`
  flex-shrink: 0;
  height: 18px;
  width: auto;
`

export const FooterLink = styled(LocalizedLink).attrs({
  activeClassName: "is-active",
})`
  display: inline-block;
  position: relative;
  text-decoration: none;

  color: #000;

  cursor: pointer;

  @media (max-width: 550px) {
    font-size: 14px;
    line-height: 24px;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    opacity: 0;

    bottom: -1px;

    height: 1px;
    width: 100%;

    background-color: #000;

    transition: opacity 0.15s ease-in-out;
  }

  &:hover:after {
    opacity: 1;

    @media (max-width: 550px) {
      opacity: 0;
    }
  }

  &.is-active {
    pointer-events: none;

    &:after {
      opacity: 1;
    }
  }
`

export const Link = styled.a`
  display: inline-block;
  position: relative;
  text-decoration: none;

  color: #000;

  cursor: pointer;

  @media (max-width: 550px) {
    font-size: 14px;
    line-height: 24px;
    color: #000;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    opacity: 0;

    bottom: -1px;

    height: 1px;
    width: 100%;

    background-color: #000;

    transition: opacity 0.15s ease-in-out;
  }

  &:hover:after {
    opacity: 1;

    @media (max-width: 550px) {
      opacity: 0;
    }
  }

  &.is-active {
    pointer-events: none;

    &:after {
      opacity: 1;
    }
  }
`

export const Button = styled.a`
  display: flex;
  align-self: flex-start;
  flex-shrink: 0;

  padding: 12px 20px;
  border-radius: 4px;

  background-color: rgba(0, 0, 0, 1);
  color: #fff;

  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`

export const LogoLink = styled(LocalizedLink)``
