import styled from "styled-components"

export const Display = styled.h1`
  font-size: 48px;
  line-height: 56px;
  font-weight: 300;

  color: #000000;

  @media (max-width: 550px) {
    font-size: 36px;
    line-height: 40px;
  }
`

export const Big = styled.h1`
  font-size: 30px;
  line-height: 44px;
  font-weight: 300;

  color: #000000;

  @media (max-width: 550px) {
    font-size: 22px;
    line-height: 32px;
  }
`

export const Heading = styled.h2`
  font-size: 22px;
  line-height: 32px;
  font-weight: ${({ medium }) => (medium ? "400" : "300")};

  color: #000000;

  @media (max-width: 550px) {
    font-size: 18px;
    line-height: 28px;
  }
`

export const Title = styled.h3`
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;

  color: ${({ white }) => (white ? "#FFF" : "#000")};

  @media (max-width: 550px) {
    font-size: 18px;
    line-height: 28px;
  }
`

export const Text = styled.p`
  font-size: 16px;
  line-height: 28px;
  font-weight: ${({ medium }) => (medium ? "400" : "300")};

  color: ${({ white }) => (white ? "#FFF" : "#000")};

  @media (max-width: 550px) {
    font-size: 14px;
    line-height: 24px;
  }
`

export const Small = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;

  color: #000000;
`

export const Label = styled.p`
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 12px;
  font-weight: 500;
  text-transform: uppercase;

  padding: 8px 12px;
  border-radius: 2px;

  color: #000000;

  background-color: #fff;
`
