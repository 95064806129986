import React, { Component } from "react"
import { injectIntl } from "react-intl"
import { navigate } from "gatsby"

import locales from "../../constants/locales"

import * as S from "./style"

const SwitchLangLink = ({ intl: { locale, formatMessage }, ...props }) => {
  const [path, setPath] = React.useState(`/${locale}`)

  React.useEffect(() => {
    const isCurrLocaleDefault = !!locales[locale].default

    setPath(
      isCurrLocaleDefault
        ? "/pt" + window.location.pathname
        : window.location.pathname.replace(`${locale}/`, "")
    )
  }, [])

  return (
    <S.NavLink to={path}>
      {formatMessage({ id: "topnav.switchLang" })}
    </S.NavLink>
  )
}

export default injectIntl(SwitchLangLink)
