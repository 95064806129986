import { createGlobalStyle } from "styled-components"

import reset from "./reset"
import style from "./style"

const GlobalStyle = createGlobalStyle`
    ${reset}
    ${style}
`

export default GlobalStyle
